@each $i in left, center, right, justify {
  .u-text-align--$(i) {
    text-align: $i;
  }

  @media (--desktop) {
    .u-text-align--$(i)\@lg {
      text-align: $i;
    }
  }

  .u-text-align--$(i)\@md {
    @media (--ipad) {
      text-align: $i;
    }
    @media (--ipad_mini) {
      text-align: $i;
    }
  }

  @media (--phone) {
    .u-text-align--$(i)\@xs {
      text-align: $i;
    }
  }
}
