.c-list {
  & &__item {
    position: relative;
    padding-left: 1em;

    &::before {
      content: '';
      position: absolute;
      top: .4em;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: currentColor;
    }
  }

  &&_themes_contact &__item {
    display: inline-block;
    min-width: 45%;
    padding: 0.3em 0 0.3em 2em;

    &::before {
      width: 1em;
      height: 1em;
      border-radius: 0;
      background: none 50%/contain no-repeat;
    }

    &--location::before {
      background-image: url(../images/icon_contact-3.png);
    }
    &--phone::before {
      background-image: url(../images/icon_contact-0.png);
    }
    &--fax::before {
      background-image: url(../images/icon_contact-1.png);
    }
    &--email::before {
      background-image: url(../images/icon_contact-2.png);
    }
  }

  &&_themes_widget &__item {
    display: block;
    padding: .4em 1.2em;
    border-bottom: 2px solid var(--green_l);
    background-color: var(--widget__bgco);
    color: black !important;

    &::before {
      visibility: hidden;
    }

    &:hover,
    &.is-current {
      background-color: var(--green_l);
    }
  }
}
