.u-adorn-border {
  position: relative;
  z-index: 2;
  padding: 20% 0 0 20%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: url(../images/adorn_border.png) 0 0/40% auto no-repeat;
  }

  &--certificate {
    display: block;
    width: max-content;
    margin: 8% auto;
    padding: 0;

    &::before {
      background-position: 0 0;
      background-size: 50% auto;
      transform: translate(-18%, -12%);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background: url(../images/adorn_border.png) 100% 100%/90% auto no-repeat;
      transform: translate(8%, 5%);
    }
  }
}
