.o-product {
  & &__content {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 100%;
    @media (--desktop) {
      width: 65%;
    }
  }
  @media (--desktop) {
    &:nth-child(2n) &__content {
      padding: 0 2em 0 0;
    }
    &:nth-child(2n+1) &__content {
      padding: 0 0 0 2em;
    }
  }
}
