.btn-menu {
  @media (--ipad){
    margin-right: 0;
    margin-top: 0;
    line-height: 1;
  }
  @media (--ipad_mini){
    margin-right: 0;
    margin-top: 0;
    line-height: 1;
  }
  @media (--phone){
    margin-right: 0;
    margin-top: 0;
    line-height: 1;

    .site-header.float-header & {
      color: var(--green);
    }
  }
}
