.c-flex {
  & &__item {
    box-sizing: border-box;
    width: calc(100% / var(--i));
  }
}

@for $i from 1 to 6 {
  .c-flex.c-flex--$(i) .c-flex__item {
    --i: $i;
  }

  .c-flex.c-flex--$(i)\@lg .c-flex__item {
    @media (--desktop) {
      --i: $i;
    }
  }

  .c-flex.c-flex--$(i)\@md .c-flex__item {
    @media (--ipad) {
      --i: $i;
    }
    @media (--ipad_mini) {
      --i: $i;
    }
  }

  .c-flex.c-flex--$(i)\@xs .c-flex__item {
    @media (--phone) {
      --i: $i;
    }
  }
}
