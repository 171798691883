.u-btn {
  display: inline-block;
  position: relative;
  padding: .5em 1em;
  border: 1px solid var(--green);
  font: 400 12px/1.2 Montserrat, sans-serif;
  text-transform: capitalize;
  color: black;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 1px;
    background-color: var(--green);
    transform: translateX(100%);
  }
}
