.u-title {
  &&_themes_half {
    position: relative;
    font: 600 8vw/1 Montserrat, sans-serif;
    color: var(--green_l);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(transparent 40%, white 86%);
      visibility: hidden;
    }
  }
}
