.c-flex {
  &:not(&--second) {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  &&--second {
    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
    }
  }
}
