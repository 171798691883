.site-footer {
  &__copyright {
    font: 12px/1 Arial, serif;
    @media (--phone) {
      text-align: center;
      line-height: 1.4;
    }
  }

  &__inspiren {
    float: right;

    @media (--phone) {
      float: none;
      display: block;
    }
  }

  &.o-contact-footer {
    background-color: white;
  }
}
