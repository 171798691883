.o-product {
  & &__cover {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    position: relative;
    z-index: 2;
    width: 100%;
    @media (--desktop) {
      width: 35%;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
    }

    &::after {
      top: 20%;
      right: 20%;
      bottom: 20%;
      left: 20%;
      background-color: var(--green_l);
    }

    &::before {
      top: 0;
      left: 10%;
      width: 25%;
      height: 75%;
      background: url(../images/adorn_border.png) 0 0/contain no-repeat;
    }
  }

  @media (--desktop) {
    &:nth-child(2n) &__cover {
      order: 2;

      &::before {
        left: auto;
        right: 10%;
      }
    }
  }
}
