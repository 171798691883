.u-color {
  &--green {
    color: var(--green_l);
  }
  &--white {
    color: white;
  }
  &--black {
    color: black;
  }
}
