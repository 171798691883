.o-product {
  & &__btn {
    display: block;
    margin-top: 2em;
    border-bottom: 1px solid var(--green);
    font: 400 12px/2.2 Montserrat, sans-serif;
    color: var(--green_l);
    @media (--phone) {
      margin-top: 1em;
    }
  }
}
