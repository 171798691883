.mainnav {
  .menu {
    &-item {
      text-transform: uppercase;
    }
  }

  &#mainnav {
    .menu {
      &-item {
        position: relative;
        padding: 0 2em;

        .site-header.float-header & > a {
          color: black;
        }

        &.current_page_item,
        &.current-menu-item,
        &.current_page_parent {
          > a {
            display: inline-block;
            position: relative;
            color: var(--green);
            font-weight: 600;

            &::after {
              content: '';
              position: absolute;
              right: -0.5em;
              bottom: -0.2em;
              left: -0.5em;
              height: 2px;
              background: linear-gradient(90deg, var(--green) 50%, white 0);
            }
          }
        }
      }
    }
  }
}
