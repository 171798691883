@define-mixin formInput {
  margin-top: 1em;
  border: 1px solid white;
  background-color: transparent;
  font: 400 1rem/1.2 Montserrat, sans-serif;
  color: white;

  &::placeholder {
    color: white;
    text-transform: capitalize;
    opacity: 1;
  }
  &::-ms-placeholder {
    color: white;
    text-transform: capitalize;
  }
  &:-ms-placeholder {
    color: white;
    text-transform: capitalize;
  }
}
.wpcf7 {
  margin-top: 1em;

  input {
    @mixin formInput;

    &[type="tel"],
    &[type="text"],
    &[type="email"] {
      width: 100%;
      @media (--desktop) {
        width: calc(( 100% - 2em ) / 3);
      }
    }

    @media (--desktop) {
      &[type="tel"],
      &[type="email"] {
        margin-left: 1em;
      }
    }

    &[type="submit"] {
      padding: .7em 4em;
      background-color: var(--blue);
      border: none;
      border-radius: 0;
      font: 400 1rem/1.2 Montserrat, sans-serif;
      color: black;
      text-transform: uppercase;
    }
  }

  textarea {
    @mixin formInput;
    resize: none;
  }
}
