.o-provide {
  & &__slogan {
    position: relative;
    padding-left: calc(15% + .7em);

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 15%;
      height: 1px;
      background-color: currentColor;
    }
  }
}
