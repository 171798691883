.o-provide {
  & &__content {
    padding: 5% 8%;
    background-color: var(--gray);
    @media (--desktop) {
      position: absolute;
      top: 50%;
      right: 0;
      left: 40%;
      transform: translateY(-50%);
      padding: 2% 5%;
    }
  }
}
