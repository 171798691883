.woocommerce {
  .o-home-products & {
    @media (--desktop) {
      .type-product {
        width: calc(( 100% - 4em ) / 5) !important;
        margin-right: 1em !important;

        &.last {
          margin-right: 0 !important;
        }
      }
    }

    .woocommerce-LoopProduct-link {
      display: block;
      position: relative;
      z-index: 2;
      width: 100%;
      padding-top: 150%;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #e3c893cc;
        opacity: 0;
        transition: opacity 1s;
      }
    }

    .attachment-woocommerce_thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100% !important;
      height: 100% !important;
      margin-bottom: 0 !important;
      object-fit: cover;
    }

    .woocommerce-loop-product__title {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1em !important;
      font: 600 1rem/1.2 Montserrat, sans-serif !important;
      text-align: left;
      word-break: keep-all;
      @media (--phone) {
        font-size: 1.1rem !important;
      }
    }

    .button {
      visibility: hidden;
      width: 100%;
      margin-top: 0 !important;
      padding: 1em;
      background-color: transparent;
      font: 400 12px/1.2 Montserrat, sans-serif;
      text-align: left;
      color: var(--green_l);

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin-top: 3px;
        background-color: color( var(--green) l(+10%) );
      }
    }

    @media (--desktop) {
      .type-product {
        &:nth-child(2n) {
          margin-top: 4em;
        }

        &:hover {
          .woocommerce-LoopProduct-link::before {
            opacity: 1;
          }

          .button {
            visibility: visible;
          }
        }
      }
    }
  }

  &.archive {
    .content-wrapper {
      padding: 5em 15px;
      @media (--desktop) {
        padding: 5em 0;
      }
    }

    #secondary,
    .button.product_type_simple {
      display: none;
    }

    #primary {
      width: 100%;
    }

    .type-product {
      width: 100% !important;

      .woocommerce-loop-product__title {
        font: 600 1.2rem/1.2 Montserrat, sans-serif !important;
      }

      .attachment-woocommerce_thumbnail {
        width: 70%;
        margin: 0 auto;
      }
    }
  }

  &.archive,
  &.single-product {
    .woocommerce-breadcrumb,
    .woocommerce-result-count,
    .woocommerce-ordering,
    .woocommerce-products-header {
      display: none;
    }

    ul.products li.product .woocommerce-LoopProduct-link p {
      color: currentColor;
    }
  }

  &.single-product {
    .product_meta {
      display: none;
    }

    .content-wrapper {
      padding: 2em 15px 5em;
      @media (--desktop) {
        padding: 5em 0;
      }

      > .row {
        display: flex;
        flex-flow: row wrap;
      }
    }

    #secondary {
      order: -1;
      @media (--ipad) {
        width: 100%;
        margin-bottom: 2em;
      }
      @media (--ipad_mini) {
        width: 100%;
        margin-bottom: 2em;
      }
      @media (--phone) {
        width: 100%;
        margin-bottom: 2em;
      }
    }

    #content div.product div.images,
    div.product div.images,
    #content div.product div.images,
    div.product div.images {
      float: right;
    }

    #content div.product div.summary,
    div.product div.summary,
    #content div.product div.summary,
    div.product div.summary {
      float: left;
    }

    .product_title {
      font: 600 1.7rem/1.2 Montserrat, sans-serif !important;
      color: var(--green_l);
    }

    .woocommerce-product-gallery {
      position: relative;
      z-index: 2;
      transform: rotateY(180deg);

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
      }

      &::after {
        top: 15%;
        right: 15%;
        bottom: 15%;
        left: 15%;
        background-color: var(--green_l);
      }

      &::before {
        top: 0;
        right: 60%;
        bottom: 30%;
        left: 0;
        background: url(../images/adorn_border.png) 100% 0/contain no-repeat;
      }

      .woocommerce-product-gallery__wrapper {
        width: 80%;
        margin: 2em auto;
      }
    }
  }
}
