.footer-widgets {
  padding: 2em 0 0;
  border: none;
  @media (--phone) {
    text-align: center;
  }

  > .container {
    padding-bottom: 1em;
    border-bottom: 2px solid gray;
  }

  .widget {
    margin-bottom: 1.5em;
  }

  @media (--desktop) {
    .widget {
      &:last-child {
        margin-bottom: 0;
      }
    }

    #media_image-2 {
      margin-top: 20%;
    }
  }

  .widget-title {
    padding-bottom: 0.5em;
    font: 600 1.2rem/1.2 Montserrat, sans-serif;

    &__small {
      display: inline-block;
      margin-left: 1em;
      font-size: 0.5em;
    }
  }

  .menu {
    .menu-item {
      display: inline-block;
      padding-right: 2em;
      text-transform: capitalize;

      &:last-child {
        padding-right: 0;
      }

      &.current_page_parent,
      &.current_page_item,
      &.current_menu_item {
        > a {
          color: var(--green_l) !important;
          font-weight: 600;
        }
      }
    }
  }
}
