.header-clone {
  display: none;
}
.site-header {
  padding: 5px 0;
  background-color: transparent;
  @media (--phone) {
    &.fixed {
      position: fixed !important;
    }

    &.float-header {
      padding: 5px 0;
    }
  }
}
