@each $i in flex-start, center, flex-end, space-between, space-around, space-evenly {
  .c-flex.c-flex_justify_$(i) {
    justify-content: $i;
  }

  .c-flex.c-flex_justify_$(i)\@lg {
    @media (--desktop) {
      justify-content: $i;
    }
  }

  .c-flex.c-flex_justify_$(i)\@md {
    @media (--ipad) {
      justify-content: $i;
    }
    @media (--ipad_mini) {
      justify-content: $i;
    }
  }

  .c-flex.c-flex_justify_$(i)\@xs {
    @media (--phone) {
      justify-content: $i;
    }
  }
}
