.widget-area:not(.footer-widgets) {
  padding: 0 2em;
  border: none;

  .widget {
    &:last-child {
      margin-bottom: 0;
    }
    
    ul li {
      padding: 0;
    }
  }

  .widget-title {
    padding: .7em 1em;
    text-transform: capitalize;
    font: 600 1.3rem/1.3 Montserrat, sans-serif;
    background-color: var(--widget__bgco);
  }
}
