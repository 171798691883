.wpb_single_image {
  .vc_figure {
    .vc_single_image-wrapper.prettyphoto {
      overflow: hidden;
      display: block;

      .vc_single_image-img {
        transition: transform 1s;
      }
    }

    &:hover {
      .vc_single_image-wrapper.prettyphoto .vc_single_image-img {
        transform: matrix(1.1, 0, 0, 1.1, 0, 0);
      }
    }
  }
}
